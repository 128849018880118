import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M45.999,25L45.999,25C45.999,24.995,46,24.99,46,24.985C46,10.808,37.97,2,25.044,2C12.26,2,4,11.022,4,24.985 C4,24.99,4.001,24.995,4.001,25h0c0.006,14.187,8.068,23,21.044,23c2.977,0,5.679-0.488,8.098-1.381l2.033,2.948 C35.362,49.838,35.671,50,36,50h6c0.372,0,0.712-0.206,0.885-0.535c0.173-0.329,0.149-0.726-0.061-1.032l-3.706-5.39 C43.519,39.068,45.996,32.865,45.999,25z M31,32h-6c-0.372,0-0.712,0.206-0.885,0.535c-0.173,0.329-0.149,0.726,0.061,1.032 l4.118,5.99C27.281,39.838,26.201,40,25.043,40C17.56,40,13,34.125,13,24.986C13,15.819,17.674,10,25.043,10 C32.384,10,37,15.819,37,24.986c0,4.396-1.078,8.016-3.002,10.609l-2.173-3.161C31.638,32.162,31.329,32,31,32z"
          transform="translate(14 17)"
          fill="currentColor"
        />
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
